import { ConfigStoreBase } from "./config-store.js";
import { FileStoreBase } from "./file-store.js";

class DropAPI {
  CONFIG_STORE = ConfigStoreBase;
  FILE_STORE = FileStoreBase;

  /** @type {((hasAccess: true) => void)|null} */
  onConfigReady = null;
  /** @type {((hasAccess: false, errorInfo?: { code: string|null, message: string|null }) => void)|null} */
  onConfigError = null;

  start() {
    this.fileStore = new this.FILE_STORE();
    this.configStore = new this.CONFIG_STORE();
  }

  stop() {
    this.fileStore = null;
    this.configStore = null;
  }

  getConfig() {
    if (!this.configStore) {
      throw new Error();
    }

    this.configStore.onConfigReady = this.configStore.onConfigError =
      /**
       * @param {boolean} hasAccess
       * @param {{code: string|null, message: string|null}} [errorInfo]
       */
      (hasAccess, errorInfo) =>
        this.handleConfigStoreChange(hasAccess, errorInfo);

    this.configStore.getConfig();
  }

  clearConfig() {
    if (!this.fileStore || !this.configStore) {
      throw new Error();
    }
    this.configStore.clearConfig();
    this.fileStore.config = null;
  }

  /**
   * @param {boolean} hasAccess
   * @param {{code: string|null, message: string|null}} [errorInfo]
   */
  handleConfigStoreChange(hasAccess, errorInfo) {
    if (!this.fileStore || !this.configStore) {
      throw new Error();
    }

    if (hasAccess) {
      this.fileStore.config = this.configStore.config;
      if (this.onConfigReady) {
        this.onConfigReady(true);
      }
    } else {
      if (this.onConfigError) {
        this.onConfigError(false, errorInfo);
      }
    }
  }
}

export default DropAPI;
