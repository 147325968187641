import Localizer from "./localizer.js";

export default class DOMLocalizer extends Localizer {
  /**
   * @override
   * @type {DOMLocalizer|null}
   **/
  static get sharedLocalizer() {
    return this.#sharedLocalizer;
  }

  /**
   * @override
   */
  static set sharedLocalizer(value) {
    this.#sharedLocalizer = value;
    Localizer.sharedLocalizer = value;
  }

  /** @type {DOMLocalizer|null} */
  static #sharedLocalizer = null;

  /** @type {WeakMap<HTMLElement, { key?: string, attr?: string, substitutions?: string }>} */
  #localizedElements = new WeakMap();

  /** @type {MutationObserver|null} */
  #observer = null;

  /**
   * @param {HTMLElement} element
   */
  observe(element) {
    element.lang = this.locale;
    this.#observer = new MutationObserver((mutations, observer) =>
      this.#mutationObserverCallback(mutations, observer),
    );

    const elements = element.querySelectorAll(
      `[data-l10n-key], [data-l10n-attr]`,
    );
    elements.forEach((element) =>
      this.#localizeElement(/** @type {HTMLElement} */ (element)),
    );

    this.#observer.observe(element, {
      attributes: true,
      attributeFilter: [
        "data-l10n-key",
        "data-l10n-attr",
        "data-l10n-substitutions",
      ],
      childList: true,
      subtree: true,
    });
  }

  /** @type {MutationCallback} */
  #mutationObserverCallback(mutations, observer) {
    mutations.forEach((mutation) => {
      const element = /** @type {HTMLElement} */ (mutation.target);
      if (mutation.type === "attributes") {
        this.#localizeElement(element);
      } else if (mutation.type === "childList") {
        element
          .querySelectorAll("[data-l10n-key]")
          .forEach((element) =>
            this.#localizeElement(/** @type {HTMLElement} */ (element)),
          );
      }
    });
  }

  /**
   * @param {HTMLElement} element
   * @returns
   */
  #localizeElement(element) {
    const l10nKeyData = element.dataset.l10nKey;
    const l10nAttrData = element.dataset.l10nAttr ?? "";
    const substitutionsJsonString = element.dataset.l10nSubstitutions;
    const substitutions = substitutionsJsonString
      ? JSON.parse(substitutionsJsonString)
      : null;

    if (this.#localizedElements.has(element)) {
      const { key, attr, substitutions } =
        this.#localizedElements.get(element) ?? {};
      if (
        key === l10nKeyData &&
        attr === l10nAttrData &&
        substitutions === substitutionsJsonString
      ) {
        return;
      }
    }

    this.#localizedElements.set(element, {
      key: l10nKeyData,
      attr: l10nAttrData,
      substitutions: substitutionsJsonString,
    });

    if (!l10nKeyData) {
      return;
    }

    const l10nKeys = l10nKeyData.split(" ");
    const l10nAttrs = l10nAttrData.split(" ");

    for (const [i, key] of l10nKeys.entries()) {
      const attr = l10nAttrs[i];
      if (attr) {
        element.setAttribute(attr, this.get(key, substitutions));
      } else {
        element.textContent = this.get(key, substitutions);
      }
    }
  }
}
