import Localizer from "./localizer.js";
import getSizeString from "./get-size-string.js";

/**
 * @typedef {CustomEvent<{key: string, folderName: string}>} BrowseCustomEvent
 */

export class DropFileHandle {
  /**
   * @param {{element: HTMLLIElement, onFileRemove: (handle: DropFileHandle) => void}} delegate
   */
  constructor(delegate) {
    this.#element = delegate.element;
    this.#delegate = delegate;
  }

  #element;
  #delegate;

  /**
   * @param {boolean} pending
   */
  setPendingDeletion(pending) {
    this.#element.classList.toggle("pending", pending);
  }

  remove() {
    this.#element.remove();
    this.#delegate.onFileRemove(this);
  }
}

export default class DropItemList {
  /**
   * @param {HTMLUListElement} element
   * @param {HTMLSpanElement} statusElement
   */
  constructor(element, statusElement) {
    this.#element = element;
    this.#element.addEventListener("click", this.#handleClickEventListener);
    this.#element.addEventListener(
      "contextmenu",
      this.#handleContextMenuEventListener,
    );
    this.#statusElement = statusElement;
  }

  #element;
  #statusElement;

  /** @type {WeakSet<DropFileHandle>} */
  #fileHandles = new WeakSet();

  #count = 0;
  #totalSize = 0;

  #handleClickEventListener =
    /**
     * @param {MouseEvent} event
     */
    (event) => this.#handleClickEvent(event);
  #handleContextMenuEventListener =
    /**
     * @param {MouseEvent} event
     */

    (event) => this.#handleContextMenuEvent(event);

  destroy() {
    this.#element.removeEventListener("click", this.#handleClickEventListener);
    this.#element.removeEventListener(
      "contextmenu",
      this.#handleContextMenuEventListener,
    );
  }

  /** @type {((deletionInfo: {key: string, filename: string, handle: DropFileHandle }) => void)|null} */
  onDeleteFile = null;

  /** @type {((browseFolderInfo: {key: string, folderName: string}) => void)|null} */
  onBrowseFolder = null;

  /**
   * @param {MouseEvent} event
   */
  #handleClickEvent(event) {
    const element = /** @type {HTMLElement} */ (event.target);

    switch (element.dataset.action) {
      case "browse": {
        const key = element.dataset.key;
        const folderName = element.dataset.folderName;
        if (key === undefined || folderName === undefined) {
          throw new Error();
        }

        event.preventDefault();

        _paq.push(["trackEvent", "UI", "BrowseFolder", key]);

        this.onBrowseFolder?.({
          key,
          folderName,
        });

        break;
      }

      case "download": {
        const name = element.dataset.filename;
        _paq.push(["trackEvent", "UI", "DownloadLinkClick", name]);
        break;
      }

      case "delete": {
        const key = element.dataset.key;
        const filename = element.dataset.filename;
        const size = parseInt(/** @type {string} */ (element.dataset.size));
        if (
          !Localizer.sharedLocalizer ||
          key === undefined ||
          filename === undefined
        ) {
          throw new Error();
        }
        if (
          !window.confirm(
            Localizer.sharedLocalizer.get("deletionConfirmation", {
              name: filename,
            }),
          )
        ) {
          return;
        }

        _paq.push(["trackEvent", "UI", "DeleteFile", key]);

        const listElement = /** @type {HTMLLIElement} */ (element.parentNode);

        const handle = new DropFileHandle({
          element: listElement,
          onFileRemove: () => {
            if (!this.#fileHandles.has(handle)) {
              return;
            }
            this.#count--;
            this.#totalSize -= size;
            this.#updateStatus();
          },
        });

        this.onDeleteFile?.({
          key,
          filename,
          handle,
        });

        this.#fileHandles.add(handle);

        break;
      }
    }
  }

  /**
   * @param {MouseEvent} event
   */
  #handleContextMenuEvent(event) {
    const element = /** @type {HTMLElement} */ (event.target);

    switch (element.dataset.action) {
      case "download": {
        const name = element.dataset.filename;
        _paq.push(["trackEvent", "UI", "DownloadLinkContextMenu", name]);
        break;
      }
    }
  }

  resetList() {
    this.#element.textContent = "";
    this.#count = 0;
    this.#totalSize = 0;
    this.#fileHandles = new WeakSet();
  }

  /**
   * @param {boolean} isReload
   */
  setListLoading(isReload) {
    if (!isReload) {
      this.resetList();
    }

    this.#statusElement.dataset.l10nKey = "listStatusLoading";
    delete this.#statusElement.dataset.l10nSubstitutions;
  }

  setListLoadingError() {
    this.resetList();
    this.#statusElement.dataset.l10nKey = "listStatusError";
    delete this.#statusElement.dataset.l10nSubstitutions;
  }

  /**
   * @param {Object} fileInfo
   * @param {string} fileInfo.key
   * @param {string} fileInfo.filename
   * @param {string} fileInfo.downloadUrl
   * @param {number} fileInfo.size
   * @param {Date} fileInfo.lastModified
   * @param {boolean} [manyItemsToAdd]
   */
  addFileToList(
    { key, filename, downloadUrl, size, lastModified },
    manyItemsToAdd = false,
  ) {
    if (!Localizer.sharedLocalizer) {
      throw new Error();
    }

    const listElement = document.createElement("li");
    listElement.classList.add("file", "item");
    listElement.dataset.key = key;
    listElement.dataset.size = size.toString();

    const detailsElement = document.createElement("details");
    const summaryElement = document.createElement("summary");
    summaryElement.dataset.l10nKey = "viewFileDetails viewFileDetails";
    summaryElement.dataset.l10nAttr = "title aria-label";
    detailsElement.appendChild(summaryElement);

    const iconElement = document.createElement("span");
    iconElement.classList.add("icon");
    iconElement.dataset.l10nKey = "fileIcon";
    iconElement.setAttribute("aria-hidden", "true");
    summaryElement.appendChild(iconElement);
    listElement.appendChild(detailsElement);

    const anchorElement = document.createElement("a");
    anchorElement.target = "_blank";
    anchorElement.href = downloadUrl;
    anchorElement.textContent = filename;
    anchorElement.dataset.action = "download";
    listElement.appendChild(anchorElement);

    const fileMetadataElement = document.createElement("ul");
    fileMetadataElement.classList.add("metadata");
    const sizeMetadataElement = document.createElement("li");
    sizeMetadataElement.dataset.l10nKey = "metadataSize";
    sizeMetadataElement.dataset.l10nSubstitutions = JSON.stringify({
      size: getSizeString(size),
    });
    fileMetadataElement.appendChild(sizeMetadataElement);
    const lastModifiedMetadataElement = document.createElement("li");
    lastModifiedMetadataElement.dataset.l10nKey = "metadataLastModified";
    lastModifiedMetadataElement.dataset.l10nSubstitutions = JSON.stringify({
      lastModified: new Intl.DateTimeFormat(Localizer.sharedLocalizer.locale, {
        dateStyle: "short",
        timeStyle: "short",
      }).format(lastModified),
    });
    fileMetadataElement.appendChild(lastModifiedMetadataElement);
    listElement.appendChild(fileMetadataElement);

    const deleteButtonElement = document.createElement("button");
    deleteButtonElement.classList.add("delete-item");
    deleteButtonElement.dataset.action = "delete";
    deleteButtonElement.type = "button";
    deleteButtonElement.dataset.key = key;
    deleteButtonElement.dataset.filename = filename;
    deleteButtonElement.dataset.size = size.toString();
    deleteButtonElement.dataset.l10nKey = "delete";
    listElement.appendChild(deleteButtonElement);

    this.#addItemToList(listElement, manyItemsToAdd);
  }

  /**
   * @param {Object} folderInfo
   * @param {string} folderInfo.key
   * @param {string} folderInfo.folderName
   * @param {boolean} [manyItemsToAdd]
   */
  addFolderToList({ key, folderName }, manyItemsToAdd = false) {
    const listElement = document.createElement("li");
    listElement.classList.add("folder", "item");

    const iconElement = document.createElement("span");
    iconElement.classList.add("icon");
    iconElement.dataset.l10nKey = "folderIcon";
    iconElement.setAttribute("aria-hidden", "true");

    listElement.appendChild(iconElement);

    const anchorElement = document.createElement("a");
    anchorElement.href = "#" + encodeURIComponent(key);
    anchorElement.dataset.action = "browse";
    anchorElement.dataset.key = key;
    anchorElement.dataset.folderName = folderName;
    anchorElement.textContent = folderName;

    listElement.appendChild(anchorElement);

    this.#addItemToList(listElement, manyItemsToAdd);
  }

  /**
   * @param {HTMLLIElement} listElement
   * @param {boolean} manyItemsToAdd
   */
  #addItemToList(listElement, manyItemsToAdd) {
    const key = /** @type {string} */ (listElement.dataset.key);
    const size = parseInt(/** @type {string} */ (listElement.dataset.size));

    this.#count++;
    if (!isNaN(size)) {
      this.#totalSize += size;
    }

    if (manyItemsToAdd) {
      this.#element.append(listElement);
    } else {
      const referenceElement = /** @type {HTMLLIElement[]} */ (
        Array.from(this.#element.children)
      ).find((element) => /** @type {string} */ (element.dataset.key) >= key);

      if (referenceElement) {
        if (key === referenceElement.dataset.key) {
          this.#element.replaceChild(listElement, referenceElement);

          this.#count--;
          // XXX: Not accurate.
          if (!isNaN(size)) {
            this.#totalSize -= size;
          }
        } else {
          this.#element.insertBefore(listElement, referenceElement);
        }
      } else {
        this.#element.append(listElement);
      }
      this.#updateStatus();
    }
  }

  manyItemsHasAdded() {
    this.#updateStatus();
  }

  #updateStatus() {
    if (!Localizer.sharedLocalizer) {
      throw new Error();
    }

    if (this.#count !== 0) {
      this.#statusElement.dataset.l10nKey =
        Localizer.sharedLocalizer.appendPluralRules(
          "listStatusItem",
          this.#count,
        );
      this.#statusElement.dataset.l10nSubstitutions = JSON.stringify({
        num: this.#count,
        totalSize: getSizeString(this.#totalSize),
      });
    } else {
      this.#statusElement.dataset.l10nKey = "listStatusNoItems";
      delete this.#statusElement.dataset.l10nSubstitutions;
    }
  }
}
