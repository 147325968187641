import StreamSlicer from "./stream-slicer.js";

/**
 * QueueUpload object includes the queue and the actual upload machinery.
 **/
class QueueUpload {
  constructor() {
    /**
     * Private Array to place files
     * @type {File[]}
     */
    this._queue = [];

    this.#isUploading = false;
  }

  xhrTimeout = 30 * 1e3;
  xhrRetries = 5;

  /**
   * Max file size allowed, in bytes.
   * false-y value to disable the check.
   */
  max_file_size = 0;

  /**
   * Use PUT or POST for uploading
   */
  method = "POST";

  /**
   * key: values to accompany the file when upload.
   * Only applicable when the HTTP_METHOD is POST.
   * @type {{[key: string]: string}}
   */
  form_data = {};

  /**
   * post field name to use for the file.
   * Only applicable when the HTTP_METHOD is POST.
   */
  post_name = "file";

  /**
   * headers to accompany the file when upload.
   * @type {{[key: string]: string}}
   */
  headers = {};

  /**
   * url to upload to.
   */
  url = "";

  /**
   * When a file starts uploading.
   * @type {((file: File) => Promise<false|any>)|null}
   **/
  onFileStart = null;
  /**
   * When a single-part upload starts.
   * Must configure upload-specific XHR parameters before resolving the promise.
   * @type {((file: File, xhr: XMLHttpRequest) => Promise<void>)|null}
   **/
  onUploadStart = null;
  /**
   * When a multi-part upload starts.
   * Must configure upload-specific XHR parameters before resolving the promise.
   * @type {((file: File, index: number, xhr: XMLHttpRequest) => Promise<void>)|null}
   **/
  onUploadPartStart = null;
  /**
   * When upload progress is made.
   * @type {((file: File, xhr: XMLHttpRequest, loaded: number, total: number, retry: number) => void)|null}
   **/
  onUploadProgress = null;
  /**
   * When a multi-part upload completes.
   * Must handle error, if any. Return `false` to stop the file upload of this file.
   * Return value will be collected and send back to `onFileComplete`.
   * @type {((file: File, xhr: XMLHttpRequest) => Promise<false|any>)|null}
   **/
  onUploadPartComplete = null;
  /**
   * When a single-part upload completes.
   * Must handle error, if any. Return `false` to stop the file upload of this file.
   * @type {((file: File, xhr: XMLHttpRequest) => Promise<false|void>)|null}
   **/
  onUploadComplete = null;
  /**
   * When a file completes uploading.
   * Will only be called if the upload is successful (i.e, `onUploadPartComplete()` or `onUploadComplete` did not return `false`.)
   * @type {((file: File, totalParts: number, partsData?: any[]) => Promise<void>)|null}
   **/
  onFileComplete = null;

  /** @type {((name: string) => void)|null} */
  onMaxFileSizeExceed = null;

  isSupported = !!(
    window.FormData &&
    window.XMLHttpRequest &&
    Array.prototype.forEach
  );

  /**
   * Private Boolean to know if the queue is currently uploading
   */
  #isUploading;

  partSize = 10 * 2 ** 20;

  getQueueLength() {
    return this._queue.length;
  }

  /**
   * @param {FileList} fileList a [object FileList] containing files to upload
   */
  addQueue(fileList) {
    if (!fileList || !this.isSupported) return;

    Array.prototype.forEach.call(fileList, (file) => {
      if (this.max_file_size && file.size > this.max_file_size) {
        this.onMaxFileSizeExceed?.(file.name);
        return;
      }

      this._queue.push(file);
    });

    if (!this.#isUploading) {
      this.#startNextUpload();
    }
  }

  /**
   * @param {File} file
   * @returns
   */
  isMultipart(file) {
    return this.partSize < file.size;
  }

  async #startNextUpload() {
    const file = this._queue.shift();

    if (!file) {
      this.#isUploading = false;
      return;
    }

    this.#isUploading = true;

    const result = await this.onFileStart?.(file);
    if (result === false) {
      await this.#startNextUpload();
      return;
    }

    if (!this.isMultipart(file)) {
      this.#continueUpload(file);
    } else {
      this.#continueMultipartUpload(file);
    }
  }

  /**
   * @param {File} file
   * @param {number} [retries]
   */
  async #continueUpload(file, retries = this.xhrRetries) {
    const xhr = new XMLHttpRequest();
    await this.onUploadStart?.(file, xhr);

    xhr.open(this.method, this.url);
    for (const name in this.headers) {
      xhr.setRequestHeader(name, this.headers[name]);
    }
    this.onUploadProgress?.(file, xhr, 0, file.size, this.xhrRetries - retries);

    xhr.upload.onprogress = (evt) => {
      this.onUploadProgress?.(
        file,
        xhr,
        evt.loaded,
        evt.total,
        this.xhrRetries - retries,
      );
    };

    xhr.timeout = this.xhrTimeout;

    xhr.onloadend = async () => {
      if ((xhr.status === 0 || xhr.status >= 500) && retries !== 0) {
        retries--;

        this.onUploadProgress?.(
          file,
          xhr,
          0,
          file.size,
          this.xhrRetries - retries,
        );

        await new Promise((resolve) =>
          setTimeout(resolve, 100 * 2 ** (this.xhrRetries - retries)),
        );

        this.#continueUpload(file, retries);
        return;
      }

      const result = await this.onUploadComplete?.(file, xhr);
      if (result !== false) {
        await this.onFileComplete?.(file, 1);
      }

      await this.#startNextUpload();
    };

    if (this.method === "POST") {
      const formData = new FormData();
      formData.append(this.post_name, file);

      for (const name in this.form_data) {
        formData.append(name, this.form_data[name]);
      }

      xhr.send(formData);
    } else {
      xhr.send(file);
    }
  }

  /**
   * @param {File} file
   */
  async #continueMultipartUpload(file) {
    const slicer = new FilePartReader(file, this.partSize);
    /** @type {any[]} */
    const partsData = [];

    /**
     * @param {File} filePart
     * @param {number} index
     * @param {number} [retries]
     */
    const continueFilePartUpload = async (
      filePart,
      index,
      retries = this.xhrRetries,
    ) => {
      const xhr = new XMLHttpRequest();
      await this.onUploadPartStart?.(file, index, xhr);

      xhr.open(this.method, this.url);
      for (const name in this.headers) {
        xhr.setRequestHeader(name, this.headers[name]);
      }
      this.onUploadProgress?.(
        file,
        xhr,
        this.partSize * index,
        file.size,
        this.xhrRetries - retries,
      );

      /**
       * @param {ProgressEvent} evt
       */
      xhr.upload.onprogress = (evt) => {
        this.onUploadProgress?.(
          file,
          xhr,
          this.partSize * index + evt.loaded,
          file.size,
          this.xhrRetries - retries,
        );
      };

      xhr.timeout = this.xhrTimeout;

      xhr.onloadend = async () => {
        if ((xhr.status === 0 || xhr.status >= 500) && retries !== 0) {
          retries--;

          this.onUploadProgress?.(
            file,
            xhr,
            this.partSize * index,
            file.size,
            this.xhrRetries - retries,
          );

          await new Promise((resolve) =>
            setTimeout(resolve, 100 * 2 ** (this.xhrRetries - retries)),
          );

          continueFilePartUpload(filePart, index, retries);
          return;
        }

        const partData = await this.onUploadPartComplete?.(file, xhr);
        if (partData === false) {
          await this.#startNextUpload();

          return;
        }

        partsData[index] = partData;
        slicer.startNextFilePart();
      };

      xhr.send(filePart);
    };

    slicer.onFilePart = continueFilePartUpload;
    slicer.onFileEnd = async (totalPartCount) => {
      await this.onFileComplete?.(file, totalPartCount, partsData);

      await this.#startNextUpload();
    };
    slicer.start();
  }
}

class FilePartReader {
  /**
   * @param {File} file
   * @param {number} partSize
   */
  constructor(file, partSize) {
    this.#file = file;
    this.#partSize = partSize;
  }

  #file;
  #partSize;
  #partCount = 0;
  /** @type {ReadableStreamDefaultReader<Uint8Array>?} */
  #reader = null;

  start() {
    this.#reader = this.#file
      .stream()
      .pipeThrough(
        new TransformStream(
          /** @type {Transformer<Uint8Array, Uint8Array>} */ (
            new StreamSlicer(this.#partSize)
          ),
        ),
      )
      .getReader();

    this.startNextFilePart();
  }

  async startNextFilePart() {
    if (!this.#reader) {
      throw new Error();
    }

    const result = await this.#reader.read();
    const part = result.value;
    if (!part) {
      this.onFileEnd?.(this.#partCount);
      return;
    }

    this.onFilePart?.(
      new File([part], this.#file.name, {
        lastModified: this.#file.lastModified,
        type: this.#file.type,
      }),
      this.#partCount,
    );
    this.#partCount++;
  }

  /**
   * When a file part is available
   * @type {((file: File, index: number) => void)|null}
   **/
  onFilePart = null;

  /**
   * When a file ends
   * @type {((totalPartCount: number) => void)|null}
   **/
  onFileEnd = null;
}

export default QueueUpload;
