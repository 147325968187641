/* Actual front-end for Drop */

import QueueUpload from "./queue-upload.js";
import DropAPI from "./drop-api.js";
import { BackBlazeB2FileStore } from "./file-store.js";
import { Base64Config } from "./config-store.js";
import Secret from "./secret.js";
import DOMLocalizer from "./dom-localizer.js";
import getSizeString from "./get-size-string.js";
import DropItemList from "./drop-item-list.js";
import DropBreadcrumb from "./drop-breadcrumb.js";
import WebPushController from "./web-push-controller.js";

/** @type {string} Current folder key, without leading slash, with trailing slash */
let listPrefix = "";

const webPushController = new WebPushController();
webPushController.onCheckingWebPushAvailability = async () => {
  if (!api.fileStore) {
    throw new Error();
  }
  return api.fileStore.getFile(WebPushController.VAPID_KEY_PATH);
};
webPushController.onGettingPushSubscriptionDetail =
  /**
   * @param {string} path
   */
  async (path) => {
    if (!api.fileStore) {
      throw new Error();
    }
    return api.fileStore.getFile(path, "json");
  };

webPushController.onAddingPushSubscriptionDetail =
  /**
   * @param {string} path
   * @param {import("./web-push-controller.js").ServerPushSubscriptionJSON} serverJson
   */
  async (path, serverJson) => {
    if (!api.fileStore) {
      throw new Error();
    }
    return api.fileStore.setFile(path, serverJson, "json");
  };
webPushController.onRemovingPushSubscriptionDetail =
  /**
   * @param {string} path
   */
  async (path) => {
    if (!api.fileStore) {
      throw new Error();
    }
    const [result] = await api.fileStore.deleteFile(path);
    return result;
  };

const queueUpload = new QueueUpload();

const api = new DropAPI();
api.CONFIG_STORE = Base64Config;
api.FILE_STORE = BackBlazeB2FileStore;
api.start();
api.onConfigReady = async function configReady() {
  _paq.push(["trackEvent", "DropAPI", "ConfigReady"]);

  listPrefix = getListPrefixFromHash();
  dropBreadcrumb.updateBreadcrumb(listPrefix);
  updateFileList();

  webPushController.loggedIn();
};
api.onConfigError =
  /**
   * @param {boolean} hasAccess
   */
  function gotConfig(hasAccess) {
    _paq.push(["trackEvent", "DropAPI", "ConfigError"]);

    alert(localizer.get("incorrectUsernamePassword"));
    secret.logout();
  };

const secret = new Secret();
secret.usernameElement = /** @type {HTMLInputElement} */ (
  document.getElementById("secret_username")
);
secret.passwordElement = /** @type {HTMLInputElement} */ (
  document.getElementById("secret_password")
);

/** @type {Date} */
const downloadLinkExpireDate = new Date(
  new Date().getTime() + 60 * 60 * 24 * 7 * 1000,
);

/** @type {WeakMap<File, string>} Map b/t file to upload and the target folder prefix */
const fileToListPrefixMap = new WeakMap();

// Allow dropping file to container
const fileContainerElement = /** @type {HTMLParagraphElement} */ (
  document.getElementById("file-container")
);
fileContainerElement.addEventListener("drop", function dropFile(evt) {
  evt.preventDefault();
  document.body.classList.remove("dragover");

  if (!secret.getSecret() || !api.configStore?.config) {
    alert(localizer.get("loginFirst"));

    return;
  }

  const files = evt.dataTransfer?.files;
  if (!files) {
    return;
  }

  Array.prototype.forEach.call(files, (file) => {
    fileToListPrefixMap.set(file, listPrefix);
    _paq.push(["trackEvent", "QueueUpload", "AddQueue", file.name]);
  });

  _paq.push([
    "trackEvent",
    "QueueUpload",
    "AddQueueLength",
    files.length.toString(),
  ]);

  _paq.push(["trackEvent", "UI", "FileDrop", files.length.toString()]);

  queueUpload.addQueue(files);
});
fileContainerElement.addEventListener("dragover", function dragoverFile(evt) {
  evt.preventDefault();
});
fileContainerElement.addEventListener("dragenter", function dragenterFile(evt) {
  document.body.classList.add("dragover");
  evt.preventDefault();
});
fileContainerElement.addEventListener("dragleave", function dragleaveFile(evt) {
  document.body.classList.remove("dragover");
  evt.preventDefault();
});

// Prevent user from leaving page when drop
// a file outside of container accidentally
window.addEventListener("drop", function dropFile(evt) {
  evt.preventDefault();
});
window.addEventListener("dragover", function dragoverFile(evt) {
  evt.preventDefault();
});

// Allow user to select files from the control
/** @type {HTMLInputElement} */ (
  document.getElementById("files")
).addEventListener("change", function changeFiles(evt) {
  if (!secret.getSecret() || !api.configStore?.config) {
    alert(localizer.get("loginFirst"));

    this.form?.reset();
    return;
  }

  if (!this.files) {
    return;
  }

  Array.prototype.forEach.call(this.files, (file) => {
    fileToListPrefixMap.set(file, listPrefix);
    _paq.push(["trackEvent", "QueueUpload", "AddQueue", file.name]);
  });

  _paq.push([
    "trackEvent",
    "QueueUpload",
    "AddQueueLength",
    this.files.length.toString(),
  ]);

  _paq.push(["trackEvent", "UI", "FileSelect", this.files.length.toString()]);

  queueUpload.addQueue(this.files);

  // Reset the from to clean up selected files
  // so user may be able to select again.
  this.form?.reset();
});

// Textual label status
const statusElement = /** @type {HTMLParagraphElement} */ (
  document.getElementById("status")
);

/**

 * @param {string} [name]
 * @param {number} [loaded]
 * @param {number} [total]
 * @param {boolean} [isRetry]
 */
function updateStatus(name, loaded, total, isRetry) {
  if (!isUploading) {
    document.body.classList.remove("uploading");
    statusElement.dataset.l10nKey = "done";
  } else {
    document.body.classList.add("uploading");
    if (
      name == undefined ||
      loaded === undefined ||
      total === undefined ||
      isRetry === undefined
    ) {
      throw new Error();
    }

    if (!DOMLocalizer.sharedLocalizer) {
      throw new Error();
    }
    const num = queueUpload.getQueueLength();
    statusElement.dataset.l10nKey =
      DOMLocalizer.sharedLocalizer.appendPluralRules(
        isRetry ? "retryUploadStat" : "uploadStat",
        num,
      );
    statusElement.dataset.l10nSubstitutions = JSON.stringify({
      name,
      loaded: getSizeString(loaded, true),
      total: getSizeString(total, true),
      present: total === 0 ? "0" : ((loaded / total) * 100).toPrecision(3),
      num: num.toString(),
    });
  }
}
if (!queueUpload.isSupported || !window.JSON) {
  statusElement.dataset.l10nKey = "unsupported";
}

/** @type {HTMLFormElement} */ (
  document.getElementById("login-form")
).addEventListener("submit", (event) => {
  event.preventDefault();
  secret.login();
});

// List of files and base href of the link to file
const dropItemList = new DropItemList(
  /** @type {HTMLUListElement} */ (document.getElementById("item-list")),
  /** @type {HTMLSpanElement} */ (document.getElementById("list-status")),
);

// Delete file when user click on a delete button
dropItemList.onDeleteFile =
  /**
   * @param {{key: string, filename: string, handle: import("./drop-item-list.js").DropFileHandle }} deletionInfo
   */
  async (deletionInfo) => {
    if (!api.fileStore) {
      throw new Error();
    }

    deletionInfo.handle.setPendingDeletion(true);
    const [result] = await api.fileStore.deleteFile("/" + deletionInfo.key);

    if (result) {
      _paq.push([
        "trackEvent",
        "DropAPI",
        "DeleteFileSuccess",
        deletionInfo.key,
      ]);
      deletionInfo.handle.remove();
    } else {
      _paq.push(["trackEvent", "DropAPI", "DeleteFileError", deletionInfo.key]);

      deletionInfo.handle.setPendingDeletion(false);
    }
  };

const dropBreadcrumb = new DropBreadcrumb(
  /** @type {HTMLUListElement} */ (document.getElementById("breadcrumb")),
);

dropItemList.onBrowseFolder = dropBreadcrumb.onBrowseFolder =
  /**
   * @param {{key: string, folderName: string}} browseFolderInfo
   */
  (browseFolderInfo) => {
    const key = browseFolderInfo.key;
    window.history.pushState(
      null,
      "",
      `#!${encodeURI(key === "" ? key : key.substring(0, key.length - 1))}`,
    );
    const isReload = listPrefix === key;
    listPrefix = key;
    dropBreadcrumb.updateBreadcrumb(listPrefix);
    updateFileList(isReload);
  };

dropBreadcrumb.onGoToFolder =
  /**
   *
   * @param {string} path
   */
  (path) => {
    if (path === "..hidden/") {
      toggleHiddenFiles();
      return;
    }

    if (path === "..experiments/") {
      toggleExperiments();
      return;
    }

    listPrefix += path;
    window.history.pushState(
      null,
      "",
      `#!${encodeURI(listPrefix.substring(0, listPrefix.length - 1))}`,
    );
    dropBreadcrumb.updateBreadcrumb(listPrefix);
    updateFileList();
  };

const getListPrefixFromHash = () => {
  if (!window.location.hash.startsWith("#!")) {
    return "";
  }

  // Clean up the human path input to avoid confusions.
  const path = decodeURIComponent(window.location.hash.substring(2))
    .replace("\\", "/")
    .replace(/\/+/, "/")
    .replace(/\/$/, "")
    .split("/")
    .map((part) => part.trim())
    .join("/");

  return path === "" || path.endsWith("/") ? path : `${path}/`;
};

window.addEventListener("popstate", () => {
  listPrefix = getListPrefixFromHash();
  dropBreadcrumb.updateBreadcrumb(listPrefix);
  updateFileList();
});

let showHiddenFiles = false;

const listStatusHiddenElement = /** @type {HTMLSpanElement} */ (
  document.getElementById("list-status-hidden")
);

document.body.addEventListener("keypress", (event) => {
  if (event.target instanceof HTMLInputElement) {
    return;
  }
  switch (event.code) {
    case "KeyH":
      toggleHiddenFiles();
      break;
    case "KeyX":
      toggleExperiments();
      break;
    case "KeyT":
      dropBreadcrumb.openGoToForm();
      break;
  }
});

const toggleHiddenFiles = () => {
  showHiddenFiles = !showHiddenFiles;
  _paq.push([
    "trackEvent",
    "UI",
    "ToggleShowHiddenFiles",
    showHiddenFiles.toString(),
  ]);

  listStatusHiddenElement.hidden = !showHiddenFiles;
  updateFileList();
};

const toggleExperiments = () => {
  _paq.push([
    "trackEvent",
    "UI",
    "ToggleExperiments",
    (!document.documentElement.classList.contains(
      "experimental-features",
    )).toString(),
  ]);

  document.documentElement.classList.toggle("experimental-features");
};

/**
 *
 * @param {(import("./file-store.js").FileInfo & { downloadUrl: string })|import("./file-store.js").FolderInfo} info
 * @param {boolean} [manyItemsToAdd]
 */
function addItemToList(info, manyItemsToAdd = false) {
  if (!api.fileStore) {
    throw new Error();
  }

  switch (info.is) {
    case "file": {
      const { key, filename, size, lastModified, downloadUrl } = info;
      if (!showHiddenFiles && filename.startsWith(".")) {
        break;
      }

      dropItemList.addFileToList(
        {
          key,
          filename,
          size,
          lastModified,
          downloadUrl,
        },
        manyItemsToAdd,
      );
      break;
    }
    case "folder": {
      if (!showHiddenFiles && info.folderName.startsWith(".")) {
        break;
      }

      dropItemList.addFolderToList(
        {
          key: info.key,
          folderName: info.folderName,
        },
        manyItemsToAdd,
      );
      break;
    }
  }
}

/**
 * @param {boolean} [isReload]
 */
async function updateFileList(isReload = false) {
  if (!api.fileStore) {
    throw new Error();
  }

  const prefix = listPrefix;
  const withHiddenFiles = showHiddenFiles;

  dropItemList.setListLoading(isReload);
  const [results, errorInfo] = await api.fileStore.listFiles(listPrefix);

  if (prefix !== listPrefix || withHiddenFiles !== showHiddenFiles) {
    // Cancelled by another updateFileList call.
    return;
  }

  if (!api.fileStore) {
    throw new Error();
  }

  if (!results) {
    if (errorInfo && errorInfo.code === "RequestTimeTooSkewed") {
      // Try again since the awsTimeOffset should have been updated now.
      updateFileList();
    } else if (errorInfo) {
      dropItemList.setListLoadingError();
      alert(
        localizer.get("fileListError") +
          "\n" +
          localizer.get("errorWithInfo", {
            code: errorInfo.code,
            message: errorInfo.message,
          }),
      );
    } else {
      dropItemList.setListLoadingError();
      alert(localizer.get("fileListError"));
    }
    return;
  }

  _paq.push(["trackEvent", "DropAPI", "ListFiles", listPrefix]);

  /** @type {((import("./file-store.js").FileInfo & { downloadUrl: string })|import("./file-store.js").FolderInfo)[]} */
  const resultsWithLinks = [];

  for (const info of results) {
    switch (info.is) {
      case "file":
        resultsWithLinks.push({
          is: info.is,
          key: info.key,
          filename: info.filename,
          lastModified: info.lastModified,
          size: info.size,
          downloadUrl: await api.fileStore.getDownloadUrl(
            "/" + info.key,
            downloadLinkExpireDate,
          ),
        });
        break;
      case "folder":
        resultsWithLinks.push(info);
        break;
    }
  }

  dropItemList.resetList();

  for (const info of resultsWithLinks) {
    addItemToList(info, true);
  }

  dropItemList.manyItemsHasAdded();
}

/**
 * The uploading status to be reflected on UI.
 * This is different from `QueueUpload##isUploading`
 * because we may deviate when API calls failed.
 */
let isUploading = false;

/**
 * @param {File} file
 */
queueUpload.onFileStart = async function (file) {
  isUploading = true;
  updateStatus(file.name, 0, file.size, false);

  const prefix = fileToListPrefixMap.get(file);
  if (prefix === undefined) {
    throw new Error("");
  }

  const uri = "/" + prefix + file.name;
  if (!api.fileStore) {
    throw new Error();
  }

  if (queueUpload.isMultipart(file)) {
    _paq.push(["trackEvent", "QueueUploadMultipart", "Start", file.name]);
    const [status, errorInfo] = await api.fileStore.createMultipartUpload(
      uri,
      file,
    );
    if (!status) {
      isUploading = false;
      updateStatus();

      _paq.push([
        "trackEvent",
        "QueueUploadMultipart",
        "StartError",
        file.name,
      ]);

      if (errorInfo) {
        alert(
          localizer.get("fileUploadStartError", { name: file.name }) +
            "\n" +
            localizer.get("errorWithInfo", {
              code: errorInfo.code,
              message: errorInfo.message,
            }),
        );
      } else {
        alert(localizer.get("fileUploadStartError", { name: file.name }));
      }

      return false;
    }
  }
};

/**
 * @param {File} file
 */
queueUpload.onUploadStart = async function (file) {
  const prefix = fileToListPrefixMap.get(file);
  if (prefix === undefined) {
    throw new Error("");
  }

  const uri = "/" + prefix + file.name;
  if (!api.fileStore) {
    throw new Error();
  }
  const uploadInfo = await api.fileStore.getUploadAuthorizationInfo(uri);

  queueUpload.method = uploadInfo.method;
  queueUpload.headers = uploadInfo.headers;
  queueUpload.headers["Content-Type"] = file.type;
  queueUpload.url = uploadInfo.url;
};

/**
 * @param {File} file
 * @param {number} index
 */
queueUpload.onUploadPartStart = async function (file, index) {
  const prefix = fileToListPrefixMap.get(file);
  if (prefix === undefined) {
    throw new Error("");
  }

  const uri = "/" + prefix + file.name;
  if (!api.fileStore) {
    throw new Error();
  }
  const uploadInfo = await api.fileStore.getUploadPartAuthorizationInfo(
    uri,
    index + 1,
  );

  queueUpload.method = uploadInfo.method;
  queueUpload.headers = uploadInfo.headers;
  queueUpload.url = uploadInfo.url;
};

// When there is a progress we will update the progress
queueUpload.onUploadProgress =
  /**
   * @param {File} file
   * @param {XMLHttpRequest} xhr
   * @param {number} loaded
   * @param {number} total
   * @param {number} retries
   */
  function (file, xhr, loaded, total, retries) {
    updateStatus(file.name, loaded, total, retries !== 0);
  };

// When upload is completed we'll process the result from server.
queueUpload.onUploadComplete =
  /**
   * @param {File} file
   * @param {XMLHttpRequest} xhr
   */
  async function (file, xhr) {
    if (!api.fileStore) {
      throw new Error();
    }
    const apiInfo = api.fileStore.handleUploadComplete(xhr);
    if (!apiInfo.success) {
      _paq.push(["trackEvent", "QueueUpload", "UploadError", file.name]);

      isUploading = false;
      updateStatus();

      const errorInfo = apiInfo.errorInfo;
      if (errorInfo) {
        _paq.push([
          "trackEvent",
          "QueueUpload",
          "UploadErrorMessage",
          errorInfo.message,
        ]);

        alert(
          localizer.get("fileUploadError", { name: file.name }) +
            "\n" +
            localizer.get("errorWithInfo", {
              code: errorInfo.code,
              message: errorInfo.message,
            }),
        );
      } else {
        _paq.push([
          "trackEvent",
          "QueueUpload",
          "UploadErrorMessage",
          "Unknown",
        ]);

        alert(localizer.get("fileUploadError", { name: file.name }));
      }

      return false;
    }

    _paq.push(["trackEvent", "QueueUpload", "UploadSuccess", file.name]);
  };

queueUpload.onUploadPartComplete =
  /**
   * @param {File} file
   * @param {XMLHttpRequest} xhr
   */
  async function (file, xhr) {
    if (!api.fileStore) {
      throw new Error();
    }

    const apiInfo = api.fileStore.handleUploadPartComplete(xhr);

    if (apiInfo.success) {
      _paq.push([
        "trackEvent",
        "QueueUploadMultipart",
        "UploadSuccess",
        file.name,
      ]);
      return apiInfo.data;
    }

    isUploading = false;
    updateStatus();

    const errorInfo = apiInfo.errorInfo;
    if (errorInfo) {
      _paq.push([
        "trackEvent",
        "QueueUploadMultipart",
        "UploadErrorMessage",
        errorInfo.message,
      ]);

      alert(
        localizer.get("fileUploadError", { name: file.name }) +
          "\n" +
          localizer.get("errorWithInfo", {
            code: errorInfo.code,
            message: errorInfo.message,
          }),
      );
    } else {
      _paq.push([
        "trackEvent",
        "QueueUploadMultipart",
        "UploadErrorMessage",
        "Unknown",
      ]);

      alert(localizer.get("fileUploadError", { name: file.name }));
    }

    return false;
  };

/**
 * @param {File} file
 * @param {number} totalPartCount
 * @param {any[]} [partsData]
 */
queueUpload.onFileComplete = async function (file, totalPartCount, partsData) {
  if (!api.fileStore) {
    throw new Error();
  }
  const prefix = fileToListPrefixMap.get(file);

  if (queueUpload.isMultipart(file)) {
    if (prefix === undefined || !partsData) {
      throw new Error("");
    }

    const uri = "/" + prefix + file.name;
    const [status, errorInfo] = await api.fileStore.completeMultipartUpload(
      uri,
      file,
      totalPartCount,
      partsData,
    );
    if (!status) {
      if (errorInfo) {
        _paq.push([
          "trackEvent",
          "QueueUploadMultipart",
          "UploadErrorCompleteMessage",
          errorInfo.message,
        ]);

        alert(
          localizer.get("fileUploadCompleteError", { name: file.name }) +
            "\n" +
            localizer.get("errorWithInfo", {
              code: errorInfo.code,
              message: errorInfo.message,
            }),
        );
      } else {
        _paq.push([
          "trackEvent",
          "QueueUploadMultipart",
          "UploadErrorCompleteMessage",
          "Unknown",
        ]);

        alert(localizer.get("fileUploadCompleteError", { name: file.name }));
      }

      isUploading = false;
      updateStatus();

      return;
    }
  }

  isUploading = false;
  updateStatus();

  if (prefix === listPrefix) {
    addItemToList({
      is: "file",
      key: prefix + file.name,
      filename: file.name,
      lastModified: new Date(file.lastModified),
      size: file.size,
      downloadUrl: await api.fileStore.getDownloadUrl(
        "/" + prefix + file.name,
        downloadLinkExpireDate,
      ),
    });
  }
};

/**
 * @param {string} name
 */
queueUpload.onMaxFileSizeExceed = (name) => {
  _paq.push(["trackEvent", "QueueUpload", "MaxFileSizeExceed", name]);

  alert(
    localizer.get("fileSizeExceedError", {
      name,
    }),
  );
};

/**
 *  @param {{ username: string; password: string; }} secret
 */
secret.onLogin = function (secret) {
  if (!api.configStore) {
    throw new Error();
  }

  _paq.push(["trackEvent", "Secret", "Login", secret.username]);

  api.configStore.SECRET = secret;
  api.getConfig();

  document.body.classList.remove("auth-needed");
  statusElement.dataset.l10nKey = "statusInstruction";
};
secret.onLogout = function loggedOut() {
  if (!api.configStore) {
    throw new Error();
  }

  _paq.push([
    "trackEvent",
    "Secret",
    "Logout",
    api.configStore.SECRET?.username,
  ]);

  api.configStore.SECRET = null;
  api.clearConfig();

  document.body.classList.add("auth-needed");
  dropItemList.resetList();
  delete statusElement.dataset.l10nKey;
  statusElement.textContent = "";

  dropBreadcrumb.emptyBreadcrumb();
  webPushController.loggedOut();
};

// Start the transition
document.body.classList.remove("uninit");

// There is no pseudo-class like :from() to target for
// the animation when leaving 'uninit' state.
// We will have to introduce a new state here.
document.body.classList.add("leave-uninit");
// We should be using animationend & webkitAnimationEnd here, however
// the event will never be triggered if the animation is interrupted.
setTimeout(function animationend() {
  document.body.classList.remove("leave-uninit");
  secret.usernameElement?.focus();
}, 1010);

document.body.classList.add("auth-needed");

const localizer = new DOMLocalizer();
localizer.observe(document.documentElement);
DOMLocalizer.sharedLocalizer = localizer;
