class Localizer {
  constructor() {
    const locale = this.#detectLocale();
    this.#setLocale(locale);
  }

  /** @type {Localizer|null} */
  static sharedLocalizer = null;

  /** @type {string} */
  #locale = "";

  /** @type {{[key: string]: string}} */
  currentStringMap = {};

  get locale() {
    return this.#locale;
  }

  set locale(locale) {
    locale = this.#detectLocale([locale]);
    this.#setLocale(locale);
  }

  /**
   * @param {string} locale
   */
  #setLocale(locale) {
    this.#locale = locale;
    this.currentStringMap = this.stringMaps[locale];

    if (typeof Intl.PluralRules === "function") {
      this.pluralRules = new Intl.PluralRules(locale);
    }
  }

  /** @type {{[key: string]: {[key: string]: string}}} */
  stringMaps = {
    "en-US": {
      username: "Username",
      password: "Password",
      login: "Login",
      done: "Done.",
      uploadStat:
        "Uploading: {{name}}, {{loaded}}/{{total}} ({{present}}%), {{num}} file(s) remaining.",
      "uploadStat.one":
        "Uploading: {{name}}, {{loaded}}/{{total}} ({{present}}%), {{num}} file remaining.",
      "uploadStat.other":
        "Uploading: {{name}}, {{loaded}}/{{total}} ({{present}}%), {{num}} files remaining.",
      retryUploadStat:
        "Retry uploading: {{name}}, {{loaded}}/{{total}} ({{present}}%), {{num}} file(s) remaining.",
      "retryUploadStat.one":
        "Retry: Uploading: {{name}}, {{loaded}}/{{total}} ({{present}}%), {{num}} file remaining.",
      "retryUploadStat.other":
        "Retry: Uploading: {{name}}, {{loaded}}/{{total}} ({{present}}%), {{num}} files remaining.",
      unsupported: "Error: Browser unsupported.",
      delete: "Delete",
      statusInstruction: `Drop files on the cloud, or click to select files.
  You may drop/select more files while uploading.`,
      incorrectUsernamePassword: "Incorrect username/password.",
      loginFirst: "You need to login first.",
      errorWithInfo:
        "The server returned the following error response:\n\n{{code}}: {{message}}",
      fileListError: "Unable to retrieve file list.",
      fileUploadError: "Upload file {{name}} failed.",
      fileUploadStartError: "Upload file {{name}} failed, unable to start.",
      fileUploadCompleteError: "Upload file {{name}} failed, unable to finish.",
      fileSizeExceedError:
        "Unable to upload, file {{name}} exceeded maximum file size.",
      deletionConfirmation: "Are you sure you want to delete file {{name}}?",
      topLevelFolder: "Top Level Folder",
      listStatusLoading: "Loading Items...",
      listStatusError: "Loading Error",
      listStatusNoItems: "No Items",
      listStatusItem: "{{num}} Item(s) · {{totalSize}}",
      "listStatusItem.one": "{{num}} Item · {{totalSize}}",
      "listStatusItem.other": "{{num}} Items · {{totalSize}}",
      goToFolder: "Go to Folder",
      path: "Folder path",
      go: "Go",
      listStatusHidden: "😶‍🌫️",
      selectFile: "Select Files to Upload",
      folderIcon: "🗂️",
      fileIcon: "📄",
      viewFileDetails: "View File Details",
      metadataSize: "Size: {{size}}",
      metadataLastModified: "Last Modified: {{lastModified}}",
      subscribe: "🔔 Subscribe to file changes",
      unsubscribe: "🔕 Unsubscribe to file changes",
      unableToSetupSubscription: "Error: Unable to setup subscription.",
      unableToCancelSubscription: "Error: Unable to cancel subscription.",
      notificationTitleUnknown: "Unknown push message",
      notificationTitleFileCreated: "File {{name}} Uploaded",
      notificationBodyFileCreated:
        "File {{name}} was uploaded in the folder {{folder}}. File size: {{fileSize}}.",
      notificationBodyFileCreatedRootFolder:
        "File {{name}} was uploaded in the top level folder. File size: {{fileSize}}.",
      notificationTitleFileRemoved: "File {{name}} Uploaded",
      notificationBodyUnknown:
        "Received an message that can't be handled. Please update the app by open it once.",
      notificationBodyFileRemoved:
        "File {{name}} was removed in the folder {{folder}}.",
      notificationBodyFileRemovedRootFolder:
        "File {{name}} was removed in the top level folder.",
      experimentMode: "Experiment Mode",
    },
    "zh-TW": {
      username: "帳號",
      password: "密碼",
      login: "登入",
      done: "上傳完成。",
      uploadStat:
        "上傳中: {{name}}，{{loaded}}/{{total}} ({{present}}%)，還有 {{num}} 個檔案。",
      "uploadStat.other":
        "上傳中: {{name}}，{{loaded}}/{{total}} ({{present}}%)，還有 {{num}} 個檔案。",
      retryUploadStat:
        "重新上傳中: {{name}}，{{loaded}}/{{total}} ({{present}}%)，還有 {{num}} 個檔案。",
      "retryUploadStat.other":
        "重新上傳中: {{name}}，{{loaded}}/{{total}} ({{present}}%)，還有 {{num}} 個檔案。",
      unsupported: "錯誤: 不支援的瀏覽器。",
      delete: "刪除",
      statusInstruction:
        "拖檔案到雲上，或是按一下雲選擇檔案。上傳時可以繼續拖放或點選增加更多檔案。",
      incorrectUsernamePassword: "帳號密碼錯誤。",
      loginFirst: "您需要先登入。",
      errorWithInfo: "伺服器回傳下列錯誤訊息:\n\n{{code}}: {{message}}",
      fileListError: "無法取得檔案清單。",
      fileUploadError: "上傳檔案 {{name}} 失敗。",
      fileUploadStartError: "上傳檔案 {{name}} 失敗，無法啟動上傳。",
      fileUploadCompleteError: "上傳檔案 {{name}} 失敗，無法結束上傳。",
      fileSizeExceedError: "無法上傳，檔案 {{name}} 超過了最大上傳大小。",
      deletionConfirmation: "您確定要刪除檔案 {{name}} 嗎？",
      topLevelFolder: "最上層資料夾",
      listStatusLoading: "載入項目中...",
      listStatusError: "載入失敗",
      listStatusNoItems: "沒有項目",
      listStatusItem: "{{num}} 個項目 · {{totalSize}}",
      "listStatusItem.other": "{{num}} 個項目 · {{totalSize}}",
      goToFolder: "開啟資料夾路徑",
      path: "資料夾路徑",
      go: "開啟",
      listStatusHidden: "😶‍🌫️",
      selectFile: "選擇上傳檔案",
      folderIcon: "🗂️",
      fileIcon: "📄",
      viewFileDetails: "查看檔案細節",
      metadataSize: "檔案大小: {{size}}",
      metadataLastModified: "修改時間: {{lastModified}}",
      subscribe: "🔔 訂閱檔案變更",
      unsubscribe: "🔕 取消訂閱檔案變更",
      unableToSetupSubscription: "錯誤: 訂閱檔案變更失敗。",
      unableToCancelSubscription: "錯誤: 取消訂閱檔案變更失敗。",
      notificationTitleUnknown: "不明的推播訊息",
      notificationTitleFileCreated: "檔案 {{name}} 已上傳",
      notificationBodyFileCreated:
        "檔案 {{name}} 已上傳到 {{folder}} 資料夾。檔案大小: {{fileSize}}。",
      notificationBodyFileCreatedRootFolder:
        "檔案 {{name}} 已上傳到最上層資料夾。檔案大小: {{fileSize}}。",
      notificationTitleFileRemoved: "檔案 {{name}} 已刪除",
      notificationBodyUnknown:
        "收到了無法處理的訊息。請打開 app 一次更新一下。",
      notificationBodyFileRemoved:
        "檔案 {{name}} 已刪除，位於 {{folder}} 資料夾。",
      notificationBodyFileRemovedRootFolder:
        "檔案 {{name}} 已刪除，位於最上層資料夾。",
      experimentMode: "測試模式",
    },
  };

  #detectLocale(userLocales = navigator.languages) {
    const supportedLocales = Object.keys(this.stringMaps);

    for (const userLocale of userLocales) {
      if (supportedLocales.includes(userLocale)) {
        return userLocale;
      }
    }

    const supportedLanguages = supportedLocales.map((locale) => {
      const index = locale.indexOf("-");
      return index !== -1 ? locale.substring(0, locale.indexOf("-")) : locale;
    });

    for (const [i, userLocale] of userLocales.entries()) {
      if (supportedLanguages.includes(userLocale)) {
        return supportedLocales[i];
      }
    }

    const userLanguages = userLocales.map((locale) => {
      const index = locale.indexOf("-");
      return index !== -1 ? locale.substring(0, locale.indexOf("-")) : locale;
    });

    for (const [i, userLanguage] of userLanguages.entries()) {
      if (supportedLanguages.includes(userLanguage)) {
        return supportedLocales[i];
      }
    }

    return Object.keys(this.stringMaps)[0];
  }

  /**
   *
   * @param {string} l10nKey
   * @param {{[key: string]: string}} [substitutions]
   * @returns
   */
  get(l10nKey, substitutions) {
    let str = this.currentStringMap[l10nKey];
    if (substitutions) {
      for (const [subKey, value] of Object.entries(substitutions)) {
        str = str.replaceAll(`{{${subKey}}}`, value);
      }
    }
    return str;
  }

  /**
   * @param {string} l10nKey
   * @param {number} num
   */
  appendPluralRules(l10nKey, num) {
    if (!this.pluralRules) {
      return l10nKey;
    }

    return `${l10nKey}.${this.pluralRules.select(num)}`;
  }
}

export default Localizer;
