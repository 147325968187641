class ConfigStoreBase {
  constructor() {
    /** @type {{region: string, bucketName: string, accessKeyId: string, secretAccessKey: string, protocol?: string}|null} */
    this.config = null;
  }

  /** @type {((hasAccess: true) => void)|null} */
  onConfigReady = null;

  /** @type {((hasAccess: false) => void)|null} */
  onConfigError = null;

  /** @type {{username: string, password: string}|null} */
  SECRET = null;

  getConfig() {
    throw new Error("Not implemented.");
  }

  clearConfig() {
    throw new Error("Not implemented.");
  }
}

/**
 * @param {string} str1
 * @param {string} str2
 * @returns {string}
 */
export const xor = (str1, str2) => {
  const textEncoder = new TextEncoder();
  const arr1 = textEncoder.encode(str1);
  const arr2 = textEncoder.encode(str2);

  return String.fromCharCode(...arr1.map((b, i) => b ^ arr2[i % arr2.length]));
};

class Base64Config extends ConfigStoreBase {
  /**
   * @override
   */
  getConfig() {
    try {
      if (!this.SECRET) {
        setTimeout(() => this.onConfigError?.(false));
        return;
      }
      const { username, password } = this.SECRET;
      const string = xor(window.atob(password), username);

      const [region, bucketName, accessKeyId, secretAccessKey] =
        string.split(":");

      if (!region || !bucketName || !accessKeyId || !secretAccessKey) {
        setTimeout(() => this.onConfigError?.(false));
        return;
      }

      setTimeout(() => {
        this.config = {
          region,
          bucketName,
          accessKeyId,
          secretAccessKey,
        };
        if (this.onConfigReady) {
          this.onConfigReady(true);
        }
      });
    } catch (error) {
      if (error instanceof DOMException && error.constructor === DOMException) {
        setTimeout(() => this.onConfigError?.(false));
      }
    }
  }

  /**
   * @override
   */
  clearConfig() {
    this.config = null;
  }
}

export { ConfigStoreBase, Base64Config };
