/**
 * @typedef {Object} FileInfo
 * @property {"file"} is
 * @property {string} key Full path to file without leading slash.
 * @property {string} filename File name
 * @property {Date} lastModified
 * @property {number} size
 */

/**
 * @typedef {Object} FolderInfo
 * @property {"folder"} is
 * @property {string} key Full path to folder without leading slash, and with trailing slash.
 * @property {string} folderName Folder name
 */

/**
 * @typedef {{code: string, message: string}} ErrorInfo
 */

/**
 * @param {ArrayBuffer} buf
 */
const bufferToHex = (buf) =>
  [...new Uint8Array(buf)].map((n) => n.toString(16).padStart(2, "0")).join("");

/**
 * @param {BufferSource} data
 * @param {BufferSource} key
 */
const hmacSha256Sign = async (data, key) => {
  const algorithm = { name: "HMAC", hash: "SHA-256" };
  return await crypto.subtle.sign(
    algorithm.name,
    await crypto.subtle.importKey("raw", key, algorithm, false, ["sign"]),
    data,
  );
};

/**
 * Encode string as documented as `UriEncode()` in AWS Sig V4 documentation.
 *
 * Specifically,
 *
 * * URI encode every byte except the unreserved characters: 'A'-'Z', 'a'-'z', '0'-'9', '-', '.', '_', and '~'.
 * * The space character is a reserved character and must be encoded as "%20" (and not as "+").
 *
 * @see https://docs.aws.amazon.com/AmazonS3/latest/API/sig-v4-header-based-auth.html#create-signature-presign-entire-payload
 *
 * @param {string} str
 * @param {boolean} [isObjectName]
 */
const uriEncode = (str, isObjectName = false) => {
  return str
    .split("")
    .map((char) => {
      const code = char.charCodeAt(0);

      if (
        (code >= 0x41 && code <= 0x5a) || // A-Z
        (code >= 0x61 && code <= 0x7a) || // a-z
        (code >= 0x30 && code <= 0x39) || // 0-9
        code === 0x2d || // -
        code === 0x2e || // .
        code === 0x5f || // _
        code === 0x7e // ~
      ) {
        return char;
      }

      if (
        isObjectName &&
        code === 0x2f // /
      ) {
        return char;
      }

      if (code <= 0xff) {
        return `%${char.charCodeAt(0).toString(16).toUpperCase().padStart(2, "0")}`;
      }
      return encodeURIComponent(char);
    })
    .join("");
};

class RetryError extends Error {
  /**
   * @param {ErrorInfo} [errorInfo]
   */
  constructor(errorInfo) {
    super("Retry");
    this.errorInfo = errorInfo;
  }
}

class FileStoreBase {
  /** @type {import("./config-store.js").ConfigStoreBase["config"]} */
  config = null;

  apiRetries = 0;

  /**
   * @param {string} uri
   * @param {Date} expireDate
   * @return {Promise<string>}
   */
  getDownloadUrl(uri, expireDate) {
    throw new Error("Not implemented.");
  }

  /**
   * @overload
   * @param {string} uri
   * @param {"json"} type
   * @returns {Promise<any|null>}
   */
  /**
   * @overload
   * @param {string} uri
   * @param {"text"} [type]
   * @returns {Promise<string|null>}
   */
  /**
   * @param {string} uri
   * @param {"json"|"text"} [type]
   * @returns {Promise<string|any|null>}
   */
  async getFile(uri, type = "text") {
    const expireDate = new Date(Date.now() + 60_000);
    try {
      const response = await fetch(await this.getDownloadUrl(uri, expireDate));

      if (response.status >= 300) {
        return null;
      }

      return await response[type]();
    } catch (error) {
      return null;
    }
  }

  /**
   * @overload
   * @param {string} uri
   * @param {any} content
   * @param {"json"} type
   * @returns {Promise<boolean>}
   */
  /**
   * @overload
   * @param {string} uri
   * @param {string} content
   * @param {"text"} [type]
   * @returns {Promise<boolean>}
   */
  /**
   * @param {string} uri
   * @param {any|string} content
   * @param {"json"|"text"} [type]
   * @returns {Promise<boolean>}
   */
  async setFile(uri, content, type = "text") {
    throw new Error("Not implemented.");
  }

  /**
   * @param {string} uri
   * @param {{[key: string]: string}} [extraHeaders]
   * @return {Promise<{url: string, headers: {[key: string]: string}, method: string}>}
   */
  async getUploadAuthorizationInfo(uri, extraHeaders) {
    throw new Error("Not implemented.");
  }

  /**
   * @param {string} uri
   * @param {File} file
   * @return {Promise<[boolean, ErrorInfo?]>}
   */
  async createMultipartUpload(uri, file) {
    throw new Error("Not implemented.");
  }

  /**
   * @param {string} uri
   * @param {number} partNumber
   * @return {Promise<{url: string, headers: {[key: string]: string}, method: string}>}
   */
  async getUploadPartAuthorizationInfo(uri, partNumber) {
    throw new Error("Not implemented.");
  }

  /**
   * @param {string} uri
   * @param {File} file
   * @param {number} totalPartCount
   * @param {any[]} partsData
   * @return {Promise<[boolean, ErrorInfo?]>}
   */
  async completeMultipartUpload(uri, file, totalPartCount, partsData) {
    throw new Error("Not implemented.");
  }

  /**
   * @param {XMLHttpRequest} xhr
   * @return {{success: true}|{success: false, errorInfo?: ErrorInfo}}
   */
  handleUploadComplete(xhr) {
    throw new Error("Not implemented.");
  }

  /**
   * @param {XMLHttpRequest} xhr
   * @return {{success: true, data: any}|{success: false, errorInfo?: ErrorInfo}}
   */
  handleUploadPartComplete(xhr) {
    throw new Error("Not implemented.");
  }

  /**
   * @param {string} [folderPath]
   * @return {Promise<[false|(FolderInfo|FileInfo)[], ErrorInfo?]>}
   */
  async listFiles(folderPath) {
    throw new Error("Not implemented.");
  }

  /**
   * @param {string} uri
   * @return {Promise<[boolean, ErrorInfo?]>}
   */
  async deleteFile(uri) {
    throw new Error("Not implemented.");
  }
}

class AmazonS3FileStore extends FileStoreBase {
  AWS_BUCKET_URL = "%protocol://%bucketName.s3.amazonaws.com%uri";

  /**
   * @override
   */
  apiRetries = 5;

  constructor() {
    super();
    this.awsTimeOffset = 0;
  }

  /** @type {{[key: string]: { uploadId: string, file: WeakRef<File> }}} */
  #uriToFileUploadDataMap = {};

  /**
   * @override
   * @param {string} uri
   * @param {Date} expireDate
   */
  async getDownloadUrl(uri, expireDate) {
    if (!this.config) {
      throw new Error();
    }

    const { region, accessKeyId } = this.config;
    const date = this._getAWSDate();
    const dateString = `${date.getUTCFullYear()}${(date.getUTCMonth() + 1)
      .toString()
      .padStart(2, "0")}${date.getUTCDate().toString().padStart(2, "0")}`;
    const dateTimeString = `${dateString}T${date
      .getUTCHours()
      .toString()
      .padStart(2, "0")}${date
      .getUTCMinutes()
      .toString()
      .padStart(2, "0")}${date.getUTCSeconds().toString().padStart(2, "0")}Z`;

    let expiresIn;
    if (this.awsTimeOffset === 0) {
      expiresIn = Math.floor((expireDate.getTime() - Date.now()) / 1000);
    } else {
      expiresIn = Math.floor(
        (expireDate.getTime() + this.awsTimeOffset - Date.now()) / 1000,
      );
    }
    let url = this._getAWSBucketObjectURL(uri);

    const host = new URL(this._getAWSBucketObjectURL()).host;

    const canonicalRequestString =
      /* HTTPMethod */ "GET\n" +
      /* CanonicalURI */ `${uriEncode(uri, true)}\n` +
      /* CanonicalQueryString */ "X-Amz-Algorithm=AWS4-HMAC-SHA256" +
      `&X-Amz-Credential=${uriEncode(`${accessKeyId}/${dateString}/${region}/s3/aws4_request`)}` +
      `&X-Amz-Date=${dateTimeString}` +
      `&X-Amz-Expires=${expiresIn}` +
      "&X-Amz-SignedHeaders=host" +
      "\n" +
      /* CanonicalHeaders */ `host:${host}\n\n` +
      /* SignedHeaders */ `host\n` +
      /* HashedPayload */ "UNSIGNED-PAYLOAD";

    const textEncoder = new TextEncoder();

    const stringToSign =
      "AWS4-HMAC-SHA256" +
      "\n" +
      dateTimeString +
      "\n" +
      `${dateString}/${region}/s3/aws4_request` +
      "\n" +
      bufferToHex(
        await crypto.subtle.digest(
          "SHA-256",
          textEncoder.encode(canonicalRequestString),
        ),
      );

    const signingKey = await hmacSha256Sign(
      textEncoder.encode("aws4_request"),
      await hmacSha256Sign(
        textEncoder.encode("s3"),
        await hmacSha256Sign(
          textEncoder.encode(region),
          await hmacSha256Sign(
            textEncoder.encode(dateString),
            textEncoder.encode("AWS4" + this.config.secretAccessKey),
          ),
        ),
      ),
    );

    url +=
      "?X-Amz-Algorithm=AWS4-HMAC-SHA256" +
      `&X-Amz-Credential=${uriEncode(`${accessKeyId}/${dateString}/${region}/s3/aws4_request`)}` +
      `&X-Amz-Date=${dateTimeString}` +
      `&X-Amz-Expires=${expiresIn}` +
      "&X-Amz-SignedHeaders=host" +
      `&X-Amz-Signature=${bufferToHex(
        await hmacSha256Sign(textEncoder.encode(stringToSign), signingKey),
      )}`;

    return url;
  }

  /**
   * @override
   * @param {string} uri
   * @param {any|string} content
   * @param {"json"|"text"} [type]
   * @returns {Promise<boolean>}
   */
  async setFile(uri, content, type = "text") {
    try {
      const response = await fetch(this._getAWSBucketObjectURL(uri), {
        method: "PUT",
        headers: await this._getAWSAuthorizationHeader(
          "PUT",
          uri,
          "",
          {},
          "UNSIGNED-PAYLOAD",
        ),
        body: type === "text" ? content : JSON.stringify(content, null, 2),
      });
      if (response.status >= 300) {
        return false;
      }

      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * @override
   * @param {string} uri
   * @param {{[key: string]: string}} [extraHeaders]
   */
  async getUploadAuthorizationInfo(uri, extraHeaders) {
    return {
      url: this._getAWSBucketObjectURL(uri),
      headers: await this._getAWSAuthorizationHeader(
        "PUT",
        uri,
        "",
        extraHeaders,
        "UNSIGNED-PAYLOAD",
      ),
      method: "PUT",
    };
  }

  /**
   * @override
   * @param {string} uri
   * @param {File} file
   */
  async createMultipartUpload(uri, file) {
    return this.#createMultipartUpload(uri, file);
  }

  /**
   * @param {string} uri
   * @param {File} file
   * @param {number} [retries]
   * @return {Promise<[boolean, ErrorInfo?]>}
   */
  async #createMultipartUpload(uri, file, retries = this.apiRetries) {
    const queryString = "uploads=";
    const url = this._getAWSBucketObjectURL(uri) + "?" + queryString;
    if (this.#uriToFileUploadDataMap[uri]) {
      const { file: fileRef } = this.#uriToFileUploadDataMap[uri];
      if (fileRef.deref() === file) {
        return /** @type {[boolean]} */ ([true]);
      }
    }

    const headers = await this._getAWSAuthorizationHeader(
      "POST",
      uri,
      queryString,
    );

    try {
      const response = await fetch(url, {
        headers,
        mode: "cors",
        method: "POST",
        body: new File([], file.name, {
          lastModified: file.lastModified,
          type: file.type,
        }),
      });
      this._updateAWSTimeOffset(response.headers.get("Date") ?? undefined);

      if (response.status >= 500) {
        throw new RetryError();
      }

      const xmlDoc = this.#parseXMLDocument(await response.text());
      const uploadId = xmlDoc.querySelector("UploadId")?.textContent;
      if (!uploadId) {
        throw new RetryError();
      }

      this.#uriToFileUploadDataMap[uri] = {
        uploadId,
        file: new WeakRef(file),
      };

      return /** @type {[boolean]} */ ([true]);
    } catch (error) {
      if (retries !== 0) {
        retries--;

        await new Promise((resolve) =>
          setTimeout(resolve, 100 * 2 ** (this.apiRetries - retries)),
        );

        return this.#createMultipartUpload(uri, file, retries);
      }

      if (error instanceof RetryError) {
        return [false, error.errorInfo];
      }

      return /** @type {[boolean]} */ ([false]);
    }
  }

  /**
   * @override
   * @param {string} uri
   * @param {number} partNumber
   */
  async getUploadPartAuthorizationInfo(uri, partNumber) {
    const uploadInfo = this.#uriToFileUploadDataMap[uri];
    if (!uploadInfo) {
      throw new Error();
    }

    const queryString =
      "partNumber=" +
      partNumber.toString() +
      "&uploadId=" +
      uriEncode(uploadInfo.uploadId);

    return {
      url: this._getAWSBucketObjectURL(uri) + "?" + queryString,
      headers: await this._getAWSAuthorizationHeader(
        "PUT",
        uri,
        queryString,
        {},
        "UNSIGNED-PAYLOAD",
      ),
      method: "PUT",
    };
  }

  /**
   * @override
   * @param {string} uri
   * @param {File} file
   * @param {number} totalPartCount
   * @param {({eTag: string})[]} partsData
   */
  async completeMultipartUpload(uri, file, totalPartCount, partsData) {
    return this.#completeMultipartUpload(uri, file, totalPartCount, partsData);
  }

  /**
   * @param {string} uri
   * @param {File} file
   * @param {number} totalPartCount
   * @param {({eTag: string})[]} partsData
   * @param {number} [retries]
   * @return {Promise<[boolean, ErrorInfo?]>}
   */
  async #completeMultipartUpload(
    uri,
    file,
    totalPartCount,
    partsData,
    retries = this.apiRetries,
  ) {
    const uploadInfo = this.#uriToFileUploadDataMap[uri];
    if (!uploadInfo) {
      throw new Error();
    }

    if (uploadInfo.file.deref() !== file) {
      throw new Error();
    }

    const queryString = "uploadId=" + uriEncode(uploadInfo.uploadId);
    const url = this._getAWSBucketObjectURL(uri) + "?" + queryString;

    const headers = await this._getAWSAuthorizationHeader(
      "POST",
      uri,
      queryString,
      {},
      "UNSIGNED-PAYLOAD",
    );

    try {
      const response = await fetch(url, {
        headers,
        mode: "cors",
        method: "POST",
        body: `<?xml version="1.0" encoding="UTF-8"?><CompleteMultipartUpload xmlns="http://s3.amazonaws.com/doc/2006-03-01/">${partsData
          .map(
            ({ eTag }, i) =>
              `<Part><PartNumber>${i + 1}</PartNumber><ETag>${eTag}</ETag></Part>`,
          )
          .join("")}</CompleteMultipartUpload>`,
      });
      this._updateAWSTimeOffset(response.headers.get("Date") ?? undefined);

      if (response.status >= 500) {
        throw new RetryError();
      }

      this.#parseXMLDocument(await response.text());
      delete this.#uriToFileUploadDataMap[uri];
      return /** @type {[boolean]} */ ([true]);
    } catch (error) {
      if (retries !== 0) {
        retries--;

        await new Promise((resolve) =>
          setTimeout(resolve, 100 * 2 ** (this.apiRetries - retries)),
        );

        return this.#completeMultipartUpload(
          uri,
          file,
          totalPartCount,
          partsData,
          retries,
        );
      }

      if (error instanceof RetryError) {
        return [false, error.errorInfo];
      }

      return /** @type {[boolean]} */ ([false]);
    }
  }

  /**
   * @override
   * @param {XMLHttpRequest} xhr
   */
  handleUploadComplete(xhr) {
    this._updateAWSTimeOffset(xhr.getResponseHeader("Date") ?? undefined);

    const xmlDoc = xhr.responseXML;
    if (xmlDoc) {
      const errorInfo = this.#getAWSErrorInfo(xmlDoc);
      if (errorInfo) {
        return {
          success: false,
          errorInfo: errorInfo,
        };
      }
    }

    if (xhr.status !== 200) {
      return {
        success: false,
      };
    }

    return {
      success: true,
    };
  }

  /**
   * @override
   * @param {XMLHttpRequest} xhr
   */
  handleUploadPartComplete(xhr) {
    this._updateAWSTimeOffset(xhr.getResponseHeader("Date") ?? undefined);

    const xmlDoc = xhr.responseXML;
    if (xmlDoc) {
      const errorInfo = this.#getAWSErrorInfo(xmlDoc);
      if (errorInfo) {
        return {
          success: /** @type {const} */ (false),
          errorInfo: errorInfo,
        };
      }
    }

    if (xhr.status !== 200) {
      return {
        success: /** @type {const} */ (false),
      };
    }

    const data = {
      eTag: xhr.getResponseHeader("ETag") ?? undefined,
    };
    return {
      success: true,
      data,
    };
  }

  /**
   * @override
   * @param {string} [listPrefix]
   * @return {Promise<[false|(FolderInfo|FileInfo)[], ErrorInfo?]>}
   */
  async listFiles(listPrefix = "") {
    return this.#listFiles(listPrefix);
  }

  /**
   * @param {string} listPrefix
   * @param {number} [retries]
   * @return {Promise<[false|(FolderInfo|FileInfo)[], ErrorInfo?]>}
   */
  async #listFiles(listPrefix, retries = this.apiRetries) {
    const searchParams = new URLSearchParams();
    searchParams.append("delimiter", "/");
    if (listPrefix) {
      searchParams.append("prefix", listPrefix);
    }
    searchParams.sort();
    const queryString = searchParams.toString().replaceAll("+", "%20");
    const url = this._getAWSBucketObjectURL() + "?" + queryString;
    const headers = await this._getAWSAuthorizationHeader(
      "GET",
      "/",
      queryString,
    );

    try {
      const response = await fetch(url, {
        headers,
        mode: "cors",
        method: "GET",
      });
      this._updateAWSTimeOffset(response.headers.get("Date") ?? undefined);

      if (response.status >= 500) {
        throw new RetryError();
      }

      const xmlDoc = this.#parseXMLDocument(await response.text());

      // Example response can be found at
      // http://docs.aws.amazon.com/AmazonS3/latest/API/RESTBucketGET.html
      const prefixNodeList = /** @type {Element} */ (
        xmlDoc.firstChild
      ).querySelectorAll("CommonPrefixes > Prefix");
      const prefixes = /** @type {string[]} */ (
        Array.prototype.map.call(prefixNodeList, function (prefix) {
          return prefix.textContent;
        })
      );

      const keyNodeList = /** @type {Element} */ (
        xmlDoc.firstChild
      ).getElementsByTagName("Key");
      const keys = /** @type {string[]} */ (
        Array.prototype.map.call(keyNodeList, function (key) {
          return key.textContent;
        })
      );
      const lastModifiedNodeList = /** @type {Element} */ (
        xmlDoc.firstChild
      ).getElementsByTagName("LastModified");
      const lastModifiedDates = /** @type {Date[]} */ (
        Array.prototype.map.call(lastModifiedNodeList, function (lastModified) {
          return new Date(lastModified.textContent);
        })
      );
      const sizeNodeList = /** @type {Element} */ (
        xmlDoc.firstChild
      ).getElementsByTagName("Size");

      const sizes = /** @type {number[]} */ (
        Array.prototype.map.call(sizeNodeList, function (size) {
          return parseInt(size.textContent);
        })
      );

      return [
        [
          ...prefixes.map((prefix) => {
            return {
              is: /** @type {const} */ ("folder"),
              key: prefix,
              folderName: prefix
                .substring(listPrefix.length)
                .replace(/\/$/, ""),
            };
          }),
          ...keys.map((key, i) => {
            return {
              is: /** @type {const} */ ("file"),
              key,
              filename: key.substring(listPrefix.length),
              lastModified: lastModifiedDates[i],
              size: sizes[i],
            };
          }),
        ],
      ];
    } catch (error) {
      if (retries !== 0) {
        retries--;

        await new Promise((resolve) =>
          setTimeout(resolve, 100 * 2 ** (this.apiRetries - retries)),
        );

        return this.#listFiles(listPrefix, retries);
      }

      if (error instanceof RetryError) {
        return [false, error.errorInfo];
      }

      return /** @type {[false]} */ ([false]);
    }
  }

  /**
   * @override
   * @param {string} uri
   * @return {Promise<[boolean, ErrorInfo?]>}
   */
  async deleteFile(uri) {
    return this.#deleteFile(uri);
  }

  /**
   * @param {string} uri
   * @param {number} [retries]
   * @return {Promise<[boolean, ErrorInfo?]>}
   */
  async #deleteFile(uri, retries = this.apiRetries) {
    const url = this._getAWSBucketObjectURL(uri);
    const headers = await this._getAWSAuthorizationHeader("DELETE", uri);

    try {
      const response = await fetch(url, {
        headers,
        mode: "cors",
        method: "DELETE",
      });
      this._updateAWSTimeOffset(response.headers.get("Date") ?? undefined);

      if (response.status >= 500) {
        throw new RetryError();
      }

      this.#parseXMLDocument(await response.text());
      return /** @type {[boolean]} */ ([response.status === 204]);
    } catch (error) {
      if (retries !== 0) {
        retries--;

        await new Promise((resolve) =>
          setTimeout(resolve, 100 * 2 ** (this.apiRetries - retries)),
        );

        return this.#deleteFile(uri, retries);
      }

      if (error instanceof RetryError) {
        return [false, error.errorInfo];
      }

      return /** @type {[boolean]} */ ([false]);
    }
  }

  /**
   * @param {string} [dateString]
   */
  _updateAWSTimeOffset(dateString) {
    if (!dateString) return;

    const currentTime = new Date().getTime();
    const awsTime = new Date(dateString).getTime();

    const offset = awsTime - currentTime;
    if (offset > 1000) this.awsTimeOffset = offset;
  }

  _getAWSDate() {
    if (this.awsTimeOffset === 0) {
      return new Date();
    }
    return new Date(new Date().getTime() + this.awsTimeOffset);
  }

  /**
   * @param {"GET"|"POST"|"PUT"|"DELETE"} method
   * @param {string} [uri]
   * @param {string} [queryString]
   * @param {{[key: string]: string}} [extraHeaders]
   * @param {string} [contentSha256]
   */
  async _getAWSAuthorizationHeader(
    method,
    uri = "/",
    queryString = "",
    extraHeaders = {},
    contentSha256 = "e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934ca495991b7852b855",
  ) {
    if (!this.config) {
      throw new Error();
    }

    const { region, accessKeyId } = this.config;
    const date = this._getAWSDate();
    const dateString = `${date.getUTCFullYear()}${(date.getUTCMonth() + 1)
      .toString()
      .padStart(2, "0")}${date.getUTCDate().toString().padStart(2, "0")}`;
    const dateTimeString = `${dateString}T${date
      .getUTCHours()
      .toString()
      .padStart(2, "0")}${date
      .getUTCMinutes()
      .toString()
      .padStart(2, "0")}${date.getUTCSeconds().toString().padStart(2, "0")}Z`;

    const host = new URL(this._getAWSBucketObjectURL()).host;

    const canonicalRequestString =
      /* HTTPMethod */ method +
      "\n" +
      /* CanonicalURI */ `${uriEncode(uri, true)}\n` +
      /* CanonicalQueryString */ queryString +
      "\n" +
      /* CanonicalHeaders */ `host:${host}\nx-amz-content-sha256:${contentSha256}\nx-amz-date:${dateTimeString}\n\n` +
      /* SignedHeaders */ `host;x-amz-content-sha256;x-amz-date\n` +
      /* HashedPayload */ contentSha256;

    const textEncoder = new TextEncoder();

    const stringToSign =
      "AWS4-HMAC-SHA256" +
      "\n" +
      dateTimeString +
      "\n" +
      `${dateString}/${region}/s3/aws4_request` +
      "\n" +
      bufferToHex(
        await crypto.subtle.digest(
          "SHA-256",
          textEncoder.encode(canonicalRequestString),
        ),
      );

    const signingKey = await hmacSha256Sign(
      textEncoder.encode("aws4_request"),
      await hmacSha256Sign(
        textEncoder.encode("s3"),
        await hmacSha256Sign(
          textEncoder.encode(region),
          await hmacSha256Sign(
            textEncoder.encode(dateString),
            textEncoder.encode("AWS4" + this.config.secretAccessKey),
          ),
        ),
      ),
    );

    return {
      Authorization:
        "AWS4-HMAC-SHA256 " +
        `Credential=${accessKeyId}/${dateString}/${region}/s3/aws4_request,` +
        `SignedHeaders=host;x-amz-content-sha256;x-amz-date,` +
        `Signature=${bufferToHex(
          await hmacSha256Sign(textEncoder.encode(stringToSign), signingKey),
        )}`,
      "x-amz-content-sha256": contentSha256,
      "x-amz-date": dateTimeString,
    };
  }

  /**
   * @param {string} [uri]
   * @param {string} [protocol]
   * @returns
   */
  _getAWSBucketObjectURL(uri = "/", protocol) {
    const config = this.config;
    if (!config) {
      throw new Error();
    }

    const url = this.AWS_BUCKET_URL.replace("%protocol", protocol || "https")
      .replace("%bucketName", config.bucketName)
      .replace("%uri", uriEncode(uri, true));

    return url;
  }

  /**
   * @param {string} responseText
   * @throws {RetryError}
   * @returns {XMLDocument}
   */
  #parseXMLDocument(responseText) {
    const parser = new DOMParser();
    const xmlDoc = /** @type {XMLDocument} */ (
      parser.parseFromString(responseText, "text/xml")
    );
    if (!xmlDoc) {
      throw new RetryError();
    }

    const errorInfo = this.#getAWSErrorInfo(xmlDoc);
    if (errorInfo) {
      throw new RetryError(errorInfo);
    }

    return xmlDoc;
  }

  /**
   * @param {XMLDocument} xmlDoc
   * @returns {ErrorInfo|null}
   */
  #getAWSErrorInfo(xmlDoc) {
    const xmlRoot = /** @type {Element} */ (xmlDoc.firstChild);
    if (xmlRoot.nodeName !== "Error") {
      return null;
    }
    // Example response can be found at
    // http://docs.aws.amazon.com/AmazonS3/latest/API/ErrorResponses.html#RESTErrorResponses
    return {
      code: xmlRoot.getElementsByTagName("Code")[0].textContent ?? "",
      message: xmlRoot.getElementsByTagName("Message")[0].textContent ?? "",
    };
  }
}

class BackBlazeB2FileStore extends AmazonS3FileStore {
  /** @override */
  AWS_BUCKET_URL = "https://%bucketName.s3.%region.backblazeb2.com%uri";

  /**
   * @override
   * @param {string} uri
   */
  _getAWSBucketObjectURL(uri = "/") {
    if (!this.config) {
      throw new Error();
    }
    const { region, bucketName } = this.config;

    return this.AWS_BUCKET_URL.replace("%region", region)
      .replace("%bucketName", bucketName)
      .replace("%uri", uriEncode(uri, true));
  }
}

export { FileStoreBase, AmazonS3FileStore, BackBlazeB2FileStore };
