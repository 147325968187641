class Secret {
  /** @type {{username: string, password: string}|null} */
  #secret = null;

  /** @type {HTMLInputElement|HTMLTextAreaElement|null} */
  usernameElement = null;
  /** @type {HTMLInputElement|HTMLTextAreaElement|null} */
  passwordElement = null;

  /** @type {((secret: {username: string, password: string}) => void)|null} */
  onLogin = null;

  login() {
    if (!this.usernameElement || !this.passwordElement) {
      throw new Error();
    }

    const secret = {
      username: this.usernameElement.value.trim(),
      password: this.passwordElement.value.trim(),
    };
    this.#secret = secret;
    this.usernameElement.value = "";
    this.passwordElement.value = "";

    setTimeout(() => this.onLogin?.(secret));
  }

  /** @type {(() => void)|null} */
  onLogout = null;

  logout() {
    this.#secret = null;

    setTimeout(() => this.onLogout?.());
  }

  getSecret() {
    return this.#secret;
  }
}

export default Secret;
