class DropGoToForm {
  /**
   * @param {{ element: HTMLFormElement, onFormSubmit: (path: string) => void, onInputBlur: () => void}} delegate
   */
  constructor(delegate) {
    this.#element = delegate.element;
    this.#element.addEventListener("submit", this.#handleSummitEventListener);
    this.#delegate = delegate;
  }

  #element;
  #delegate;

  focusInput() {
    const inputElement = /** @type {HTMLInputElement} */ (
      this.#element.querySelector("input")
    );
    inputElement.focus();
    /**
     *
     * @param {FocusEvent} event
     * @returns
     */
    const handleBlurEvent = (event) => {
      const relatedTarget = /** @type {HTMLElement|null} */ (
        event.relatedTarget
      );

      // Allow focus to move between input and button without hiding the form.
      if (relatedTarget?.parentNode === inputElement.parentElement) {
        relatedTarget.addEventListener("blur", handleBlurEvent, { once: true });
        return;
      }
      this.#delegate.onInputBlur();
    };

    inputElement.addEventListener("blur", handleBlurEvent, { once: true });
  }

  /**
   * @param {SubmitEvent} event
   */
  #handleSummitEventListener = (event) => this.#handleSummitEvent(event);

  /**
   * @param {SubmitEvent} event
   */
  #handleSummitEvent(event) {
    event.preventDefault();

    const formData = new FormData(this.#element);
    this.#delegate.onFormSubmit(/** @type {string} */ (formData.get("path")));
    this.#element.reset();
  }

  destroy() {
    this.#element.removeEventListener(
      "submit",
      this.#handleSummitEventListener,
    );
  }
}

export default class DropBreadcrumb {
  /**
   * @param {HTMLUListElement} element
   */
  constructor(element) {
    this.#element = element;

    this.#goToForm = new DropGoToForm({
      element: /** @type {HTMLFormElement} */ (
        this.#element.querySelector("form")
      ),
      onFormSubmit: (path) => {
        this.#handleFormSubmit(path);
      },
      onInputBlur: () => {
        this.#detailsElement.open = false;
      },
    });
    this.#element.addEventListener("click", this.#handleClickEventListener);
    this.#detailsElement = /** @type {HTMLDetailsElement} */ (
      this.#element.querySelector("details")
    );
    this.#detailsElement.addEventListener(
      "toggle",
      this.#handleToggleEventListener,
    );
  }

  #element;
  #detailsElement;
  #goToForm;

  emptyBreadcrumb() {
    for (const element of Array.from(this.#element.children)) {
      if (element.classList.contains("breadcrumb-folder")) {
        element.remove();
      }
    }
  }

  /**
   * @param {string} prefix
   */
  updateBreadcrumb(prefix) {
    this.emptyBreadcrumb();

    const prefixes = prefix.split("/");
    prefixes.pop();

    let key = "";

    const rootListElement = this.#createListElement(key, "", "🪣");
    rootListElement.classList.add("root");
    const rootAnchorElement = /** @type {HTMLAnchorElement} */ (
      rootListElement.firstChild
    );
    rootAnchorElement.dataset.l10nKey = "topLevelFolder topLevelFolder";
    rootAnchorElement.dataset.l10nAttr = "title aria-label";
    this.#element.insertBefore(rootListElement, this.#element.lastElementChild);

    for (const [i, folderName] of prefixes.entries()) {
      key += folderName + "/";
      const listElement = this.#createListElement(key, folderName);
      if (i + 1 === prefixes.length) {
        listElement.classList.add("current");
      }
      this.#element.insertBefore(listElement, this.#element.lastElementChild);
    }
  }

  destroy() {
    this.#element.removeEventListener("click", this.#handleClickEventListener);
    this.#detailsElement.removeEventListener(
      "toggle",
      this.#handleToggleEventListener,
    );
    this.#goToForm.destroy();
  }

  /** @type {((browseFolderInfo: {key: string, folderName: string}) => void)|null} */
  onBrowseFolder = null;

  #handleClickEventListener =
    /**
     * @param {MouseEvent} event
     */
    (event) => this.#handleClickEvent(event);

  /**
   * @param {MouseEvent} event
   */
  #handleClickEvent(event) {
    const element = /** @type {HTMLElement} */ (event.target);

    switch (element.dataset.action) {
      case "browse": {
        const key = element.dataset.key;
        const folderName = element.dataset.folderName;
        if (key === undefined || folderName === undefined) {
          throw new Error();
        }

        event.preventDefault();

        _paq.push(["trackEvent", "UI", "BreadcrumbBrowseFolder", key]);

        this.onBrowseFolder?.({
          key,
          folderName,
        });

        break;
      }
    }
  }

  #handleToggleEventListener =
    /**
     * @param {Event} event
     */
    (event) => this.#handleToggleEvent(event);

  /**
   * @param {Event} event
   */
  #handleToggleEvent(event) {
    if (this.#detailsElement.open) {
      this.#goToForm.focusInput();
    }
  }

  openGoToForm() {
    this.#detailsElement.open = true;
    this.#goToForm.focusInput();
  }

  /** @type {((path: string) => void)|null} */
  onGoToFolder = null;

  /**
   * @param {string} path
   */
  #handleFormSubmit(path) {
    // Clean up the human path input to avoid confusions.
    path = path
      .replace("\\", "/")
      .replace(/\/+/, "/")
      .replace(/\/$/, "")
      .split("/")
      .map((part) => part.trim())
      .join("/");

    if (path !== "") {
      this.onGoToFolder?.(path + "/");
    }

    this.#detailsElement.open = false;
  }

  /**
   * @param {string} key
   * @param {string} folderName
   * @param {string} [displayName]
   */
  #createListElement(key, folderName, displayName = folderName) {
    const listElement = document.createElement("li");
    listElement.classList.add("breadcrumb-folder");
    const anchorElement = document.createElement("a");
    anchorElement.href = "#" + encodeURIComponent(key);
    anchorElement.dataset.action = "browse";
    anchorElement.dataset.key = key;
    anchorElement.dataset.folderName = folderName;
    anchorElement.textContent = displayName;

    listElement.appendChild(anchorElement);
    return listElement;
  }
}
