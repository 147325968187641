export default class DropWebPushUI {
  /**
   *
   * @param {HTMLElement} element
   */
  constructor(element) {
    this.#element = element;
    this.#element.addEventListener("click", this.#handleClickEventListener);
  }

  destroy() {
    this.#element.removeEventListener("click", this.#handleClickEventListener);
  }

  #element;

  /** @type {"hidden"|"unsubscribed"|"subscribed"} */
  #uiState = "hidden";

  /**
   * @param {import("./web-push.js").WebPushState} state
   */
  updateUIWithWebPushState(state) {
    this.#element.classList.remove(this.#uiState);
    this.#element.hidden = false;
    switch (state) {
      case "subscribed":
        this.#element.classList.add(state);
        this.#uiState = state;
        this.#element.dataset.l10nKey = "unsubscribe";
        break;
      case "unsubscribed":
        this.#element.classList.add(state);
        this.#uiState = state;
        this.#element.dataset.l10nKey = "subscribe";
        break;
      default:
        this.#element.hidden = true;
        this.#uiState = "hidden";
        break;
    }
  }

  #handleClickEventListener =
    /**
     * @param {MouseEvent} event
     */
    (event) => this.#handleClickEvent(event);

  /** @type {(() => void)|null} */
  onSubscribe = null;
  /** @type {(() => void)|null} */
  onUnsubscribe = null;

  /**
   * @param {MouseEvent} event
   */
  #handleClickEvent(event) {
    if (this.#uiState === "subscribed") {
      this.onUnsubscribe?.();
    }
    if (this.#uiState === "unsubscribed") {
      this.onSubscribe?.();
    }
  }
}
