/**
 * @typedef {"unknown"|"unsupported"|"unsubscribed"|"subscribed"} WebPushState
 */

export default class WebPush {
  /** @type {WebPushState} */
  #state = "unknown";

  get state() {
    return this.#state;
  }

  /** @type {((state: WebPushState, previousState: WebPushState) => void)|null} */
  onStateChange = null;

  /**
   * @param {WebPushState} state
   **/
  #updateState(state) {
    if (state === this.#state) {
      return;
    }
    const previousState = this.#state;
    this.#state = state;
    this.onStateChange?.(state, previousState);
  }

  /** @type {(() => Promise<boolean>)|null} */
  onReceivingClientSubscription = null;
  /** @type {(() => Promise<boolean>)|null} */
  onRemovingClientSubscription = null;

  async #getSubscription() {
    const swReg = await navigator.serviceWorker.getRegistration();

    if (!swReg) {
      this.#updateState("unsupported");
      return null;
    }

    if (!swReg.pushManager) {
      this.#updateState("unsupported");
      return null;
    }

    const pushSubscription = await swReg.pushManager.getSubscription();
    if (!pushSubscription) {
      this.#updateState("unsubscribed");
      return null;
    }

    this.pushSubscription = pushSubscription;
    if (!(await this.onReceivingClientSubscription?.())) {
      this.#updateState("unsubscribed");
      return null;
    }

    this.#updateState("subscribed");
    return pushSubscription;
  }

  /** @type {string | BufferSource | null} */
  vapidPublicKey = null;

  /** @type {PushSubscription|null} */
  pushSubscription = null;

  async checkState() {
    if (this.state !== "unknown") {
      return;
    }
    await this.#getSubscription();
  }

  async subscribe() {
    if (this.state !== "unsubscribed") {
      return false;
    }
    const swReg = await navigator.serviceWorker.getRegistration();
    if (!swReg) {
      this.#updateState("unsupported");
      return false;
    }

    const pushSubscription = await swReg.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: this.vapidPublicKey,
    });

    this.pushSubscription = pushSubscription;
    if (!(await this.onReceivingClientSubscription?.())) {
      return false;
    }
    this.#updateState(pushSubscription ? "subscribed" : "unsubscribed");

    return /** @type {boolean} */ (!!pushSubscription);
  }

  async unsubscribe() {
    if (this.state !== "subscribed") {
      return false;
    }

    if (!this.pushSubscription) {
      await this.#getSubscription();
    }
    if (!this.pushSubscription) {
      return false;
    }
    if (!(await this.onRemovingClientSubscription?.())) {
      return false;
    }

    const result = await this.pushSubscription?.unsubscribe();
    this.#updateState(result ? "unsubscribed" : "subscribed");
    if (result) {
      this.pushSubscription = null;
    }
    return result;
  }
}
